<!-- System: STA
    Purpose: This compoment will display add credit
              card model for payment purpose.
-->

<template>
  <!-- Credit Card Modal -->
  <v-dialog v-model="creditCardModal" width="500">
    <v-card id="modal-addCard" ref="modal_addCard">
      <v-card-title class="headline white lighten-2 justify-center">
        Add Card
        <v-spacer></v-spacer>
        <v-btn icon @click="creditCardModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- Credit Card Form -->
      <v-form
        @reset.prevent="closingModal"
        @submit.prevent="onSubmit"
        id="payment-form"
        v-model="valid"
      >
        <!-- Display Plan If Available -->
        <v-row>
          <v-col cols="12" md="10" offset-md="1" class="mt-3">
            <span style="font-size: small" v-if="option.plan">
              <strong>Your plan</strong>
              Per User {{ option.plan }} ({{ option.product }}) (${{
                option.amount
              }})
            </span>
          </v-col>
          <!--Card Name Text Field-->
          <v-col cols="12" md="10" offset-md="1" class="mt-n5">
            <v-text-field
              v-model="cardDetail.name"
              label="Name on Card"
              :rules="required"
            ></v-text-field>
          </v-col>
          <!--Card Phone Number Text Field-->
          <v-col cols="12" md="10" offset-md="1" class="mt-n6">
            <v-text-field
              v-model="cardDetail.phone"
              label="Phone Number"
              type="text"
              required
              :rules="phoneNumberRules"
            ></v-text-field>
          </v-col>
          <!--Card Address Text Field-->
          <v-col cols="12" md="10" offset-md="1" class="mt-n8">
            <v-text-field
              v-model="cardDetail.streetAddress"
              label="Address"
              type="text"
              :rules="required"
            ></v-text-field>
          </v-col>
          <!--Country Dropdown-->
          <v-col cols="12" md="10" offset-md="1" class="mt-n5">
            <v-select
              v-model="cardDetail.country"
              :items="countries"
              label="Select Country"
              required
            ></v-select>
          </v-col>
          <!-- Team Member Quantity Text Field-->
          <v-col cols="12" md="10" offset-md="1" class="mt-n8">
            <v-text-field
              :readonly="!!check_subscription_quantity"
              v-model="cardDetail.teamMemberQuantity"
              v-if="!check_subscription"
              label="Team Members Quantity"
              type="text"
              :rules="TeamSize"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="10" offset-md="1" class="mt-n2 mb-5">
            <div class="w-100" id="card-modal-element">
              <!-- A Stripe Element will be inserted here. -->
            </div>
            <!-- Used to display form errors. -->
            <div id="card-modal-errors" class="error--text pt-4" role="alert"></div>
          </v-col>
          <!-- Add Card Button -->
          <v-row>
            <v-col cols="12" md="10" offset-md="1">
              <v-btn
                color="#2758f6"
                class="white--text mb-5"
                ref="submit"
                type="submit"
                :disabled="!valid"
              >
                Add Card
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PaymentMethodMixin from "@/components/Billing/PaymentMethodMixin";
import { required, phoneNumberRules, TeamSize } from "@/Constants/Const";
export default {
  name: "CreditCardModel",
  mixins: [PaymentMethodMixin],
  data() {
    return {
      creditCardModal: false,
      phoneNumberRules: phoneNumberRules,
      TeamSize: TeamSize,
      required: required,
      valid: true,
      option: {
        plan: null,
        product: null,
        amount: 0,
      },
    };
  },
  computed: {
    check_subscription() {
      return this.$store.state.custom.company_subscription_status;
    },
    check_subscription_quantity() {
      return this.$store.state.custom.subscription_quantity;
    },
  },
  mounted() {
    /**
     * Custom event to trigger credit
     * card model when new card is added
     */
    this.$root.$on("add-new-card", () => {
      this.creditCardModal = true;
      let that = this;
      setTimeout(that.mountStripeElement(), 3000);
    });
    /**
     * Custom event to trigger credit
     * card model when a new plan is selected
     */
    this.$root.$on("selectedPlan", (data) => {
      this.creditCardModal = true;
      this.option.plan = data.plan === "yearly_plan" ? "Yearly" : "Monthly";
      this.option.product = data.product.replace(/^\w/, (c) => c.toUpperCase());
      this.option.amount = data.amount;
      this.cardDetail.stripe_plan = data.plan;
      this.cardDetail.stripe_product = data.product;
      let that = this;
      setTimeout(that.mountStripeElement(), 3000);
    });
    // Handle real-time validation errors from the card Element.
    this.cardElement.addEventListener("change", ({ error }) => {
      const displayError = document.getElementById(this.errorElementId);
      if (error) {
        this.$root.$emit("snack_bar", {
          show: true,
          message: error.message,
          snackbarColor: "red",
        });
        // displayError.textContent = error.message;
      } else {
        displayError.textContent = "";
      }
    });
    setTimeout(
      this.$nextTick(() => {
        this.cardDetail.teamMemberQuantity = Math.max(
          this.check_subscription_quantity,
          0
        );
      }),
      3000
    );
  },
  methods: {
    /**
     * This function is resposible for
     * mount stipe element into form
     */
    mountStripeElement() {
      this.$nextTick(function () {
        this.cardElement.mount("#card-modal-element");
        this.errorElementId = "card-modal-errors";
      });
    },
  },
};
</script>

<style scoped>
</style>

